import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import SideForm from "./components/Sides/RightSide/SideForm";
import LeftSide from "./components/Sides/LeftSide/LeftSide";
import Loader from "./components/component/Loader/Loader";
import Thankyoupage from "./components/component/ThankYouPage/Thankyoupage";
import Disclaimer from "./components/component/Disclaimer/Disclaimer";
import { fetchLocation } from "./config/apicalling";
import { data } from "./config/data";

const HomePage = React.memo(({ isSmallScreen, imagesLoading, dialCode }) => {
  const [showThankYouPage, setShowThankYouPage] = useState(false);

  return (
    <div style={{ display: "flex" }}>
      {imagesLoading ? (
        <Loader />
      ) : (
        <>
          {showThankYouPage ? (
            <Thankyoupage setIsThankYouPage={setShowThankYouPage} />
          ) : (
            <>
              <div
                style={isSmallScreen ? { width: "100vw" } : { width: "77vw" }}
              >
                <LeftSide
                  dialCode={dialCode}
                  setIsThankYouPage={setShowThankYouPage}
                />
              </div>
              {!isSmallScreen && (
                <div
                  style={{
                    width: "23vw",
                    position: "sticky",
                    height: "100vh",
                    backgroundColor: "white",
                    top: "0",
                  }}
                >
                      <SideForm
                        data = {data}
                    setIsThankYouPage={setShowThankYouPage}
                    dialCode={dialCode}
                      />  
                      
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
});

const App = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [imagesLoading, setImagesLoading] = useState(false);
  const [dialCode, setDialCode] = useState('');
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1150);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <Routes>
      <Route
        path="/"
        element={
          <HomePage
            dialCode={dialCode}
            isSmallScreen={isSmallScreen}
            imagesLoading={imagesLoading}
          />
        }
      />
      {/* <Route path="/thankyou" element={<Thankyoupage />} />  */}
      <Route path="/disclaimer" element={<Disclaimer />} />
    </Routes>
  );
};

export default App;
