export const data = {
  logo: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fishva.png44a1f810-bcaf-4cfe-986f-d747d40b08cf?alt=media&token=5cea9bf4-e157-47e4-9816-548a3c93f33e",

  projectName: "TARC Ishva - Google Ads",

  //heroSection

  heroSection: {
    mobileImages: [
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fishav%20mobile.webpc62d8a4f-be7d-42b9-9098-3bd2286d2bba?alt=media&token=0b6d3a15-986b-4742-8e1b-b5fc6ca3091d",
    ],

    desktopImages: [
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fishav%20desktop.webp53f58bff-9e36-40f1-a661-f1487d40de6c?alt=media&token=79f1c221-fa74-497d-a7b1-1a3afe74fa88",
    ],

    heading: "Overview",

    addressHeading: "TARC Ishva",

    addressSubHeading: "At Sector 63A, Gurgaon by The Anant Raj Corporation",

    description:
      "TARC Ishva in Sector 63A, Gurgaon, is an exquisite ultra-luxury residential project crafted by The Anant Raj Corporation, featuring opulent 3.5 BHK and 4.5 BHK apartments with cutting-edge amenities. Spanning 7 acres, this prestigious development boasts 5 majestic high-rise towers with 400 elegantly designed apartments, each reflecting a Victorian-inspired aesthetic. The architecture is marked by distinctive elements such as bay windows, turrets, spires, tiled courtyards, intricate mosaic detailing, and meticulously hand-painted stucco finishes, all contributing to an aura of timeless sophistication.<br></br> Nestled on the upscale Golf Course Extension Road, Tarc Ishva enjoys seamless connectivity to key city destinations, including elite schools, top-tier hospitals, and premier shopping centers. Located a mere 100 meters from DLF Arbour, this prime location enhances its desirability. With spacious 3 BHK apartments of 3,000 sq. ft. and 4 BHK apartments of 4,000 sq. ft., Tarc Ishva offers an unparalleled living experience that epitomizes luxury and elegance.",

    button: "Download Brochure",

    popupData: {
      heading: "Download Brochure ",

      button: "Download Brochure",
    },
  },

  //card data

  cardData: {
    popupData: {
      heading: "Enquiry ",

      button: "Enquiry",
    },

    heading: "Pre Launch Phase",

    mainHeading: "TARC Ishva",

    address: "At Sector 63A, Gurgaon",

    subAddress: "By The Anant Raj Corporation",

    fields: [
      { key: "7 Acres", label: "Land Area" },

      { key: "2029", label: "Possession" },

      { key: "G+45", label: "Floors" },
    ],

    buttonData: {
      discount: "Launching Soon!",

      plantTime: "Luxury 3.5 & 4.5 BHK Apartments",

      edition: "TARC Ishva",
    },

    description: "Schedule your VC or Site visit!",

    price: "5.84 Cr Onwards",

    // button: "Book your VC Now",

    rera: "RERA No. : To be announced",
  },

  //Price

  price: {
    heading: "Tentative Price",

    tableHeading: ["Type", "Area (approx.)", "Price"],

    plans: [
      {
        type: "3 BHK + S",

        sba: "2850 sq ft",

        price: "Rs. 5.84 Cr",

        button: "Request For Details",
      },
      {
        type: "3 BHK + S",

        sba: "2950 sq ft",

        price: "Rs. 6.05 Cr",

        button: "Request For Details",
      },
      {
        type: "4 BHK + S",

        sba: "3800 sq ft",

        price: "Rs. 7.79 Cr",

        button: "Request For Details",
      },

      {
        type: "4 BHK + S",

        sba: "3900 sq ft",

        price: "On request",

        button: "Request For Details",
      },
    ],
  },

  //Site & Floor Plan

  siteAndFloorPlan: {
    heading: "Site & Floor Plan",

    popupData: {
      heading: "Request for plan details",

      button: "Request for plan details ",
    },

    floorPlans: [
      {
        heading: "Master Plan Plan",

        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fmasterplanishva.webpb4cd9b0b-08d4-456b-a113-4c3a18d4aa54?alt=media&token=8f5f19be-4fbc-4dfc-a4d0-260da058eba4",

        button: "Master Plan",

        hoverButton: "Enquire Now",
      },

      {
        heading: "Floor Plan",

        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ffloorplanishva.webp7f47a890-b559-4ffe-9af9-12ad54dc629a?alt=media&token=6dc51a18-dd1d-4839-b57d-3916a77158a3",

        button: "3.5 BHK (2850 Sqft)",

        hoverButton: "Enquire Now",
      },
      {
        heading: "Floor Plan",

        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ffloorplanishva.webp7f47a890-b559-4ffe-9af9-12ad54dc629a?alt=media&token=6dc51a18-dd1d-4839-b57d-3916a77158a3",

        button: "3.5 BHK (2950 Sqft)",

        hoverButton: "Enquire Now",
      },
      {
        heading: "Floor Plan",

        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ffloorplanishva.webp7f47a890-b559-4ffe-9af9-12ad54dc629a?alt=media&token=6dc51a18-dd1d-4839-b57d-3916a77158a3",

        button: "4.5 BHK (3800 Sqft)",

        hoverButton: "Enquire Now",
      },

      {
        heading: "Floor Plan",

        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ffloorplanishva.webp7f47a890-b559-4ffe-9af9-12ad54dc629a?alt=media&token=6dc51a18-dd1d-4839-b57d-3916a77158a3",

        button: "4.5 BHK (3900 Sqft)",

        hoverButton: "Enquire Now",
      },
    ],
  },

  //AMENITIES

  amenities: {
    heading: "Amenities",

    image: false,

    images: [
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Famp.svgcdd31981-cadc-466e-b4ca-39b0646e70f0?alt=media&token=07c20efe-e5e5-4517-b133-26ee07a641c3",

        bottomSideHeading: "AMPHITHEATRE",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fbasketball-court.svg6a0442ac-3a47-492e-aa67-b33fd7672a47?alt=media&token=a3fcfd38-5006-4686-aeec-41ed7ad3f11a",

        bottomSideHeading: "BASKETBALL COURT",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fcricket.svgdc6f93ec-beec-4839-a7c3-e028d5ef5f00?alt=media&token=9891bd72-9d16-4d73-8774-13bd68b29639",

        bottomSideHeading: "CRICKET PRACTICE NET",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftable-tennis.svgcae56e9d-c1b2-43a8-8b36-62f46a0162a8?alt=media&token=8aeaa039-24fc-43af-90eb-a0e1145a3396",

        bottomSideHeading: "TABLE TENNIS",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fjogging.svg9aa328dd-c386-4bd4-9751-b5d87f9a8006?alt=media&token=10dcca65-1c34-4152-8ddb-a474574cb92a",

        bottomSideHeading: "JOGGING TRACK",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fgym.svga6462c92-2def-4067-9c45-c9013ee32c2b?alt=media&token=39cf2c2f-edb0-4aff-bf8d-bb9c63058dd3",

        bottomSideHeading: "GYM",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Freflexology.svg272a3d7e-481c-4f3e-a0a3-8f8ddfe88bfb?alt=media&token=e36ab038-8c51-4836-bee0-c1acfb379f01",

        bottomSideHeading: "REFLEXOLOGY PATHWAY",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fswm.svg0936427f-6d8c-44a1-b5ad-e172b28b65c4?alt=media&token=ccb6d048-d265-4c33-bd26-56b3f1ac1edb",

        bottomSideHeading: "SWIMMING POOL",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fkids.svge0ffa078-52ac-44ec-ae4e-1b843e328fe8?alt=media&token=1ffe893d-8644-4c14-b94b-bd531d98b773",

        bottomSideHeading: "KIDS PLAY AREA",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftrack-bicycle.svgb2654b89-e1be-4fdd-ade2-54cbbcf12175?alt=media&token=194a5e91-96c1-471d-8d8d-0c06dc9ad0db",

        bottomSideHeading: "CYCLING TRACK",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fyoga.svg220df747-446e-49da-afd7-eb796a43bee5?alt=media&token=8239ab9b-75a4-4f89-bb74-4dc49aaf6ceb",

        bottomSideHeading: "YOGA LAWN",
      },
    ],

    icons: [
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Famp.svgcdd31981-cadc-466e-b4ca-39b0646e70f0?alt=media&token=07c20efe-e5e5-4517-b133-26ee07a641c3",

        bottomSideHeading: "AMPHITHEATRE",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fbasketball-court.svg6a0442ac-3a47-492e-aa67-b33fd7672a47?alt=media&token=a3fcfd38-5006-4686-aeec-41ed7ad3f11a",

        bottomSideHeading: "BASKETBALL COURT",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fcricket.svgdc6f93ec-beec-4839-a7c3-e028d5ef5f00?alt=media&token=9891bd72-9d16-4d73-8774-13bd68b29639",

        bottomSideHeading: "CRICKET PRACTICE NET",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftable-tennis.svgcae56e9d-c1b2-43a8-8b36-62f46a0162a8?alt=media&token=8aeaa039-24fc-43af-90eb-a0e1145a3396",

        bottomSideHeading: "TABLE TENNIS",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fjogging.svg9aa328dd-c386-4bd4-9751-b5d87f9a8006?alt=media&token=10dcca65-1c34-4152-8ddb-a474574cb92a",

        bottomSideHeading: "JOGGING TRACK",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fgym.svga6462c92-2def-4067-9c45-c9013ee32c2b?alt=media&token=39cf2c2f-edb0-4aff-bf8d-bb9c63058dd3",

        bottomSideHeading: "GYM",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Freflexology.svg272a3d7e-481c-4f3e-a0a3-8f8ddfe88bfb?alt=media&token=e36ab038-8c51-4836-bee0-c1acfb379f01",

        bottomSideHeading: "REFLEXOLOGY PATHWAY",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fswm.svg0936427f-6d8c-44a1-b5ad-e172b28b65c4?alt=media&token=ccb6d048-d265-4c33-bd26-56b3f1ac1edb",

        bottomSideHeading: "SWIMMING POOL",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fkids.svge0ffa078-52ac-44ec-ae4e-1b843e328fe8?alt=media&token=1ffe893d-8644-4c14-b94b-bd531d98b773",

        bottomSideHeading: "KIDS PLAY AREA",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftrack-bicycle.svgb2654b89-e1be-4fdd-ade2-54cbbcf12175?alt=media&token=194a5e91-96c1-471d-8d8d-0c06dc9ad0db",

        bottomSideHeading: "CYCLING TRACK",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fyoga.svg220df747-446e-49da-afd7-eb796a43bee5?alt=media&token=8239ab9b-75a4-4f89-bb74-4dc49aaf6ceb",

        bottomSideHeading: "YOGA LAWN",
      },
    ],
  },

  //Gallery

  gallery: {
    heading: "Gallery",

    images: [
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fishva%20gallery%201.webpdfa71e93-0814-4fa3-835e-6efd5e71406c?alt=media&token=168b23f3-d18a-42f4-a0c2-36541dcf10dd",

        bottomSideHeading: "Artistic Impression",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fishva%20gallery%202.webp6e94429d-dbd1-466e-8c83-df4deba98c3b?alt=media&token=85a9a613-de7a-4fbf-8775-076a9a8910f4",

        bottomSideHeading: "Artistic Impression",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fishva%20gallery%203.webp05b55c8d-319c-4bbe-902b-300356919430?alt=media&token=2c710355-c3cc-400d-ab44-59cd0d5f7694",

        bottomSideHeading: "Artistic Impression",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fishva%20gallery%204.webpaeb826d2-d231-430c-bc12-a6e563ba2545?alt=media&token=9a4475d6-d398-4290-887c-893449fed0a8",

        bottomSideHeading: "Artistic Impression",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fishva%20gallery%205.webp0a23a769-f1a1-4f53-b504-cbc0b4e39318?alt=media&token=39f9ce38-965f-4743-8f7b-63f454088ad4",

        bottomSideHeading: "Artistic Impression",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fishva%20gallery%206.webp9244561d-af0a-4b5f-87c9-10702c3df48b?alt=media&token=da8dde65-f535-4597-bdf6-180030b7d4e0",

        bottomSideHeading: "Artistic Impression",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia_7.webpffc71ce2-ad05-43c8-8bfe-16f5beb41ee6?alt=media&token=6ed4f272-aa51-4361-bfd2-a6340697b8dd",

        bottomSideHeading: "Artistic Impression",
      },

      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia_8.webp50297fba-6471-4484-ab02-1147aad0fc29?alt=media&token=dc5d1daa-97a3-41c0-8457-82d9778adae5",

        bottomSideHeading: "Artistic Impression",
      },
    ],
  },

  //LOCATION

  location: {
    heading: "Location",

    mapView: {
      heading: "Map View",

      mapLink:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.256388397246!2d77.08205187501633!3d28.39306677579645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d230bc1385d89%3A0xac9217e7328cc3f6!2sTARC%20Ishva%2063A%20Gurugram!5e1!3m2!1sen!2sin!4v1725355286094!5m2!1sen!2sin",

      button: "Know More About Location",

      popupData: {
        heading: "Explore Location",

        button: "Request Location",
      },
    },

    locationMap: {
      heading: "Location Map",

      imageLink:
        "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fishva%20location.jpgaf4335d2-e0bc-4c3b-87d5-a8b6eab4023b?alt=media&token=3d64abed-5e2c-4cf8-91f7-0395880f9ab1",

      button: "View Location Map",

      popupData: {
        heading: "Explore Location",

        button: "Request Location",
      },
    },
  },

  //Virutal  Taur

  virtualSiteTour: {
    heading: "Virtual Tour",

    items: [
      {
        heading: "VIRTUAL SITE TOUR",

        subHeading: "TARC Ishva, Sector 63A, Gurgaon",

        bottomSideHeading: "Artistic Impression",

        topSideHeading: "",

        backgroundImage:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fishav%20virtual.webpc44d4204-4159-4690-b923-73dbdc88bfa7?alt=media&token=7a342ee3-df9f-40fe-95b3-f53343e3d453",

        popupData: {
          heading: "Request For Virtual Site Tour",

          button: "Virtual Site Tour",
        },
      },

      {
        heading: "SAMPLE FLAT",

        subHeading: "TARC Ishva, Sector 63A, Gurgaon",

        bottomSideHeading: "Artistic Impression",

        // topSideHeading: "Unleash Your Golfing Skills on Breathtaking 9-Hole Chip & Golf Greens",

        backgroundImage:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fvalencia%20sample%20apt.webp18f561ff-035d-4e6a-bb69-3cd8b2e04d87?alt=media&token=3669569e-7883-41c6-a6bc-221b0e4cf413",

        popupData: {
          heading: "Request For Virtual Site Tour",

          button: "Virtual Site Tour",
        },
      },
    ],
  },

  //about the developer

  aboutthedeveloper: {
    heading: "About The Developer",

    para: "The Anant Raj Corporation (TARC) is a leading real estate developer in India with nearly 40 years of experience. Founded by Anil Sarin, TARC began as a construction and contracting firm and has grown into one of the largest real estate development companies and land bank holders in the New Delhi Metropolitan Area. Known for their strong work ethics and customer-centric approach, TARC has earned a reputation as a trusted developer in the region. Their vision focuses on creating vibrant, thoughtfully designed spaces that cater to customer needs. TARC's diverse portfolio includes luxury residences, commercial complexes, and land developments in Gurgaon and Delhi, with notable projects such as TARC Kailasa, TARC Tripundra, TARC Maceo, TARC Hauz Khas, and TARC Residences Chattarpur.",
  },

  footer: {
    topLine: "Channel Partner RERA No. : RC/REA/HARERA/GGM/1327/922/2O2O/26",

    bottomLine: "Project RERA No. : To Be Announced",

    link: "https://haryanarera.gov.in/",

    disclimer:
      "This is not the official website of the developer & property, it belongs to an authorised channel partner for information purposes only. All rights for logos & images are reserved to the developer. Thank you for visiting our website. This disclaimer Disclaimer is applicable to this website and all microsites and websites owned by us. By using or accessing this website you agree with the Disclaimer without any qualification or limitation. This website is in the process of being updated. By accessing this website, the viewer confirms that the information including brochures and marketing collaterals on this website are solely for informational purposes only and the viewer has not relied on this information for making any booking/purchase in any project of the company. Nothing on this website, constitutes advertising, marketing, booking, selling or an offer for sale, or invitation to purchase a unit in any project by the company. The company is not liable for any consequence of any action taken by the viewer relying on such material/information on this website.",

    footerAllRight:
      "All Rights Reserved. © 2024 Copyright PropCrown Global LLP",
  },

  details: {
    whatsappNumber: "+919990887599",

    phoneNumber: "+919990887599",

    whatsappMessage:
      "Hi, I am interested in TARC Ishva. Please share the details with me.",
  },

  formData: {
    placeHolder: "Please suggest a time (in IST) to get in touch.",
    phonePlaceholder : "Phone number with country code",
    popup: {
      rows: 3,
    },
    sideFormRows: 2,
  },

  //thank you form

  thankYouPage: {
    heading: "Thank You",

    thankyouText: "for filling the form",

    image:
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fishva%20thank%20you.webp827c7ec4-2d1e-40af-b8b8-b4b9f415fc05?alt=media&token=97f583e7-27a2-4e20-a45c-0536fbd49df4",

    appreciateLines: {
      lineOne: "We appreciate your interest",

      lineTwo: "and will get back to you shortly.",
    },
  },

  disclaimer: ` 

  <section> 

    <h2>Disclaimer</h2> 

    <p> 

      This website is only for the purpose of providing information regarding real estate projects in different regions. By accessing this website, the viewer confirms that the information including brochures and marketing collaterals on this website is solely for informational purposes and the viewer has not relied on this information for making any booking/purchase in any project of the company. Nothing on this website constitutes advertising, marketing, booking, selling or an offer for sale, or invitation to purchase a unit in any project by the company. The company is not liable for any consequence of any action taken by the viewer relying on such material/ information on this website. 

    </p> 

    <p> 

      Please also note that the company has not verified the information and the compliances of the projects. Further, the company has not checked the RERA (Real Estate Regulation Act 2016) registration status of the real estate projects listed herein. The company does not make any representation in regards to the compliances done against these projects. You should make yourself aware of the RERA registration status of the listed real estate projects before purchasing property. 

    </p> 

    <p> 

      This site is for information purposes only and should not be treated as the official website. 

    </p> 

  </section> 

 

  <section> 

    <h2>Privacy Policy</h2> 

    <h3>Updation of privacy policy</h3> 

    <p> 

      This privacy policy is subject to undergo change and review without any prior notice or approval. So to keep yourself updated on the changes introduced, please keep visiting and reviewing the terms and conditions of this privacy policy. 

    </p> 

 

    <h3>User information</h3> 

    <p> 

      By using our website, you agree to abide by the rules laid out by us and consent to the collection and use of all such information that you may furnish to, or through, our website. In some cases, while you visit our website, you may not need to provide any personal information. But in certain instances, we must have your personal information in order for us to grant you access to some of the links or sites. Such links/ pages may ask for your name, e-mail address, phone number, etc. The information furnished by you is used to provide relevant products and services and to acknowledge receipt of your communication or to send out information and updates to you. You have the option of requesting removal from our mailing list. We do not give away your personal information to any third party. 

    </p> 

 

    <h3>Security</h3> 

    <p> 

      To ensure security while transferring sensitive information, all the ongoing transmissions between client and server are encrypted using advanced and standard protocols. We also practice restricted access by employees and hold them to high levels of confidentiality. 

    </p> 

 

    <h3>Use of cookies</h3> 

    <p> 

      We may use cookies for security, session continuity, and customization purposes. In case of a user opting to reject a cookie, he/ she may not be able to gain access to some of the limited services or use some features of the site. 

    </p> 

 

    <p> 

      In case of any queries or suggestions regarding the privacy statement or your dealings with this website, please contact: 

    </p> 

  </section> 

`,

  popup: {
    leftSide: {
      heading: "We Promise",

      iconsImages: [
        {
          label: "Instant Call Back",

          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/imageuploader-7809e.appspot.com/o/images%2Fcall-back-icon-16.jpgfeb2b5f4-9b58-4eae-87e9-c8aca81d5e45?alt=media&token=bb54e66f-5637-4ca0-9f40-2008ee8b2802",
        },

        {
          label: "Project Video Conferencing",

          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FVC.svgcb712f12-aac9-492a-a2f7-74c63a3918ea?alt=media&token=3848f904-807c-4e6c-9751-832759017e10",
        },

        {
          label: "Unmatched Price",

          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/imageuploader-7809e.appspot.com/o/images%2Fbest-deal-badge-icon-best-deal-banners-badge-sticker-sign-tag-best-offer-modern-style-illustration-vector.jpg2c5a7144-c7c9-4913-b229-5280ed00fdc4?alt=media&token=ddf9f9c3-d403-4961-9557-850792a7b112",
        },
      ],
    },

    rightSide: {
      heading: "Project Highlights",

      points: [
        "Pre Launch Phase",

        "Excellent Connectivity",

        "4 Side Open Homes",

        "Preference for NRIs",
      ],
    },
  },

  sideForm: {
    topBarLeft: {
      label: "Organise Site Visit",

      popupData: {
        heading: "Request For Virtual Site Tour",

        button: "Virtual Site Tour",
      },

      topBarRight: {
        label: "Whatsapp",

        imageLink: "",
      },
    },

    buttonAfterCallButton: {
      label: "Request for Call Back",

      popupData: {
        heading: "Request For Call Back",

        button: "Request For Call Now ",
      },
    },
  },

  backendUrl: "https://landing-pages-backend.onrender.com",
}; 

 

//image ratio 


// hero = >  

//   desktop : 1183*730 

//   mobile : 390*400 

//logo =>92*54 

//price => 420*227 

// floorPlan => 425*319 

//amenites => square 

//gallery => square  

//location =>687*310 

//video => 569*323 
